import AppRoutes from './components/AppRoutes';
import './scss/main.scss';

function App() {
  return (
<AppRoutes />
  );
}

export default App;
